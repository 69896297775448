.App {
  text-align: center;
}


.page{
  width: 100%;
  float: left;
}


