.DpTitle{
    float: left;
    width: 96%;
    
    padding: 10% 2% 2% 2%;
}
.ages{
    float: left;
    width: 25%;
    height: fit-content;
}
.fullpic{
    float: left;
    width: 50%;
    height: fit-content;
}

.Skillset{
    float: left;
    width: 25%;
    height: fit-content;
}
.fphoto{
    width: 55%;
    margin-top: 15%;
}
.tp1area{
    width: 100%;
    float:left;
    margin:10% 0 5% 0;
    background-color: #000;
}
.tocntr{
    width: 87%;
    margin: 0 auto;
    min-height: 190px;
}
.wfull{
    float: left;
    WIDTH: 100%;
    min-height: auto;
}
.tube{
    display: inline-block;
    height: 20pt;
    width: 70pt;
    border-radius: 50px;
}
.GG{
    background-image: linear-gradient(to right bottom, #85dd8c, #94e285, #a4e77e, #b4ec76, #c6f06f, #d0f166, #dbf15d, #e6f154, #ecee46, #f2ea36, #f8e623, #ffe200);
}
.RG{
    background-image: linear-gradient(to right bottom, #000bff, #6900fd, #9400fb, #b500f9, #d200f7, #e600e3, #f500d0, #ff00bf, #ff009b, #ff0077, #ff0054, #ff0031);
}
.BoldHeadingStyle{
    font-size: clamp(1.2em, 4vw, 2.5em);
    font-family: 'FallingSkyBoldplus';
    letter-spacing: clamp(.1em, 4vw, .3em);

}
.tpcntr{
    margin: 0 auto;
    display: inline-block;
    width: 65%;
}

@media screen and (max-width:750px) {

    .ages {
    width: 100%;
}
    .Skillset {
        width: 100%;
    }
    .fullpic {
        width: 100%;
    }
    .BoldHeadingStyle{
        letter-spacing: 6pt;
    }
    .tpcntr{
        width: 36%;
    }
 
}


