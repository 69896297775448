.HeadingTxt1{
    font-family: 'Playfair';
    font-size: 50pt;
    text-align: left;
    margin-bottom: 10pt;
    line-height: 40pt;
}
.SubTxt1{
    font-family: 'FallingSkyBoldplus';
    font-size: 16pt;
    text-align: left;
}

.SubTxt2{
    font-family: 'Roboto-Regular';
    font-size: 7pt;
    text-align: justify;
}

.Tp1{
   
    float: left;
    width: 100%;
    height: fit-content;
    margin: 10% 0;
    border-left: solid 6px #ff0081;
    padding-left: 5px;
    border-radius: 7px;
}
@media screen and (max-width: 900px) {
    
    .HeadingTxt{
        font-size: 30pt;
        
    }
    
}