.bstyle{
   
    border: none;
    color: colour;
    padding: 35px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15pt;
    cursor: pointer;
    font-family: 'FallingSkyBoldplus';
  }

  .bstyle:hover {
    border-radius: 10px;
  }