.Footertitle{
    width: 50%;
    float: left;
    height: 50vh;
}
.formplacer{
    height: fit-content;
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
   
    
}
.Blackfooter{
    width: 100%;
    float: left;
    height: fit-content;
    background-color: #000;
    margin-top: 20px;
    padding-bottom: 50px;
}
.Footerdiv{
    float: left;
    width: 100%;
}
.Theform{
    float: left;
    min-width: 271px;
    height: 80%;
    margin:4%;
    border-radius: 25px;
    padding: 4%;
}


.fotterp{
    color: #fff;
    width: 68%;
    text-align: left;
    margin-left: 4%;
    font-size: 14pt;
}
.dpphoto{
    border-radius: 50px;
    height: 20pt;
    width: 20pt;
    float: left;
}
.displyleft{
    display: block;
    text-align: left;
    font-family: 'Roboto-Regular';
    font-size: clamp(.7em, 4vw, 1.1em);
    margin: 2%;
    width: 100%;
}

.bbs{
    border: .7px solid rgba(43, 42, 42, 0.403);
    height: 50px;
    border-radius: 10px;
}
input:focus {
    border:1px solid #000;
  }

  .heightta{
    height: 250px;
  }
  .formstyle{
    width: 50%;
    height: fit-content;
  }
  .white{
    color: #fff;
    text-decoration: none;
  }