.floatrow{
    display: flex;
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.blogslistitem{
    width: 100%;
    height: fit-content;
    margin :20px 5% 20px 5% ;
    border-bottom: 1px solid;
    border-color: rgb(220, 220, 220);
    cursor: pointer;
}
.ash{
    color: #a6a6a6;;
}