.Blogsection{
    width: 70%;
    margin: 5% auto;
    height: fit-content;
    padding: 3%;
}
.Bsh1{
    text-align: left;
    font-family: 'Playfair';
    font-size: clamp(2em, 4vw, 3em);
    margin: 0;
}
.Bshp1{
    text-align: left;
    padding-bottom: 1%;
    font-family: 'Roboto-Regular';
    margin: 0;
    font-size: clamp(.5em, 3vw, 1em);
}
.Bspj{
    border-top: .5px solid ;
    padding-top: 2%;
    border-color: rgb(154, 154, 154);
    text-align: justify;
    font-family: 'Roboto-Regular';
    font-size: clamp(.5em, 3vw, 1em);
}