.tocntrveertical{
    width: 70%;
    margin: 0 auto;
    height: fit-content;
}
.greekphoto{
    width: 130px;
    margin-top: 10%;
}

.cbox2{
    float: left;
    width: 87%;
    border-radius: 25px;
    margin: 3%;
    padding: 12px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.c2imgdiv{
    float: left;
    width:80px;
    height: 80px;
    background-color: #000;
    border-radius: 25px;
    margin-right: 2%;
    margin-bottom: 5%;
}
.c2boxtxt{
    float: left;
    width: 80%;
    min-height: 80px;
    font-family: 'Roboto-Bold';
    font-size: 10pt;
    text-align: justify;
    margin-bottom: 5%;
}
.c2boxname{
    font-family: 'Roboto-Regular';
    font-size: 10pt;
    float: right;
    text-align: right;

}

@media screen and (max-width:480px) {
    .c2boxname{
        margin: 5% 0;
    
    }
   
}