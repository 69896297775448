
.HeadingTxtSS{
  font-family: 'FallingSkyBoldplus';
  font-size: Clamp(35pt, 4vw, 50pt);
  text-align: left;
  margin-bottom: 3pt;
  letter-spacing: -3px;
  line-height: 45px;
  color: #fff;
}
.swiper {
  font-family: 'FallingSkyBoldplus';
    font-size: 7pt;
    text-align: left;
    width: 85%;
}
.SubTxtSS{
  text-align: justify;
  color: #fff;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  color: rgb(255, 255, 255);
  width: 70px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
