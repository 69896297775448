.minilogo{
    width: 30px;
    margin: 15% 0 15% 0;
}
.minilogo:hover{
    width: 35px;
}
.sidenav{
    float:right;
    display: flex;
    flex-direction: column;
    width: 30px;
    z-index: 1;
    padding: 2%;
    position: absolute;
    right: 0;
    top: 7vh;
}
.rightfixed{
    position: fixed;

}
.minidiv{
    width: 30px;
    height: fit-content;
    

}
.minibm{
    font-family: 'FallingSkyBoldplus';
    margin-bottom: 45px;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 10px;
    letter-spacing: 7px;
}

@media screen and (max-width:700px) {

    .minilogo{
        width: 23px;
    }
    .minilogo:hover{
        width: 27px;
    }
}