.Imagerea{
    min-width: 50%;
    float: left;
    background-image: url(../Images/Aboutimage.JPG);
    background-size: cover;
    background-position: center;
    height: 100vh;
}
.cntr{
    float: left;
    margin-top: 50%;
    margin-left: 30%;
    text-align: justify;
   
}

.fontstyle1{
    position: absolute;
    font-family: 'FallingSkyBoldplus';
    font-size: 70px;
    color: #fff;
}
.strip{
    position: relative;
    width: 211px;
    height: 55px;
   
}
.Textarea{
    width: 50%;
    float: left;
    text-align: left;
    height: 100vh;
}
.abouttxt{
    
    width: 70%;
    font-size: 12pt;
    font-family: 'Roboto-Regular';
    margin-top: 25%;
    margin-left: 15%;
    letter-spacing: 1pt;
    line-height: 25pt;
    text-align: justify;
}
    

.aboutbutton{
    margin-top: 4%;
    margin-left: 15%;
    float: left;
    
    
}

@media screen and (max-width: 1000px){

    .Textarea{
    width: 100%;
    height: fit-content;
    margin-bottom: 70px;
   

    }
    .Imagerea{
        width: 100%;
        height: 50vh;
    }
    .cntr{
        margin-top: 25%;
        
       
    }
    .abouttxt{
        margin: 10% auto;
    }
    .aboutbutton{
        margin: 4% 14% ;

    }
    

}





