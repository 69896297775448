.bpstyle{
    border: none;
    color: white;
    background-color: #ffffff00;
    margin: 3% 5% 2% 5%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Roboto-Bold';
  }
  .bpstyle:hover{
    color: rgb(243, 9, 110);
  }