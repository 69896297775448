.Launch{
    background-image: url(../Images/launchbkg.JPG);
    background-size: cover;
    background-position: top;
    
    height: 100vh;
}
.LHiGK{
    width: 311px;
    text-align: left;
    position: absolute;
    top: 30%;
    margin-left: 4%;
}
.Lsubtitle{
    position: absolute;
    bottom:0;
    width: 80%;
    min-height: 150px;
    padding: 0 0 0 4%;

    /* From https://css.glass */
    background: rgb(255 255 255 / 38%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
    
}
.blk{
    text-align: left;
    display: flex;
}

.boldf{
    font-family: 'FallingSkyBoldplus';
}

.gkbold{
    font-size: 74px;
    line-height: 53px;
    letter-spacing: -3px;
    font-family: 'FallingSkyBoldplus';
}

.hibold{
    font-size: 30px;
    letter-spacing: -2px;
}
.Ldda{
    height: 100%;
    font-size: clamp(2.4em, 4vw, 3em);
    letter-spacing: -2px;
    line-height: clamp(1em, 4vw, 1em);
    margin: 2% 5% 2% 0%;
}
.Lddas{
    height: 100%;  
    top: 0;
    margin: 2% 12% 7% 0;
    font-size: clamp(.9em, 2vw, 1em);
    width: 55%;
    text-align: justify;
    font-family: 'Roboto-Bold';
}
.Lddb{
    float: right;
}
.abs{
    position: absolute;
}

@media screen and (max-width:700px) {

   
    .Lsubtitle{
        width: 96%;
        background-image: none;
    }
    .Launch{
        background-image: none;
        background-color: white;
    }
}