.cbox3{
   
    border-radius: 25px;
    height: 350px;
    width: 300px;
    padding: 12px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.cbox3:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 27px;
}

.c3imgdiv{
    float: left;
    width: 100%;
    height: 275px;
    background-color: #000;
    border-radius: 25px;
    margin-bottom: 2%;
    cursor: pointer;
}
.c3boxtxt{
    float: left;
    width: 80%;
    font-family: 'Roboto-Bold';
    font-size: 12pt;
    text-align: left;
    margin-bottom: 1%;
}
.c3boxsubtxt{
    font-family: 'Roboto-Regular';
    font-size: 7pt;
    color: rgb(59, 59, 59);
    float: left;
    text-align: left;

}
.swiper {
    font-family: 'FallingSkyBoldplus';
      font-size: 7pt;
      text-align: left;
      width: 85%;
      min-height: 175px;
  }

.swiper-slide {
    text-align: center;
    font-size: 18px;
    color: #000;
    width: 70px;
    
    
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }