.LogoL{
    float: left;
}
.Header{
    height: 50px;
    z-index: 1;
    padding: 1%;
    position: fixed;
}

@media screen and (max-width:600px) {
    .LogoL{
        width: 55%;
    }
}