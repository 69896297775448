body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100% ;

}
@font-face {
  font-family: 'FallingSkyBoldplus';
  src: local('FallingSkyBoldplus'), url(./Fonts/FallingSkyBoldplus-6GZ1.otf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(./Fonts/Roboto-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto-Thin'), url(./Fonts/Roboto-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(./Fonts/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair';
  src: local('Playfair'), url(./Fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf) format('truetype');
}
